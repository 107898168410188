.bd-main {
    padding: 4.3rem .5rem 1rem .5rem; }

.signin .card-content {
  background-color: #f9f6f6;
  border: none;
  padding: 20px; }

.signin .verify-email {
  max-width: 420px; }

.verify-email-content {
  margin-bottom: 1.5rem; }

.signin-logo .card {
  box-shadow: none; }

.signin-lang .card {
  box-shadow: none;
  padding-top: 3rem; }

.signin .title {
    margin-bottom: 1.5rem; }

.signin .subtitle {
  overflow-wrap: break-word;
  word-break: break-word;
  max-width: 300px; }

.signin-form {
    margin: 1.5rem 0; }

.signin-spacer {
    margin: 1.5rem 0; }

.signin .notification {
  width: 314px; }

.card-footer-item.register {
  background-color: #284863;
  color: white; }

.input {
  border-width: 1px; }

.card-footer {
  border: none;
  cursor: pointer; }

.label {
  font-weight: bold; }

.mock-input {
  border: solid 1px grey;
  min-height: 35px;
  line-height: 35px;
  padding: 0 5px; }

.mock-input-content {
  border: none;
  min-height: 35px;
  line-height: 37px; }

.social-signin .button {
    margin-bottom: 8px; }

.is-github {
    color: #fff;
    background-color: #444;
    border-color: rgba(0,0,0,.2); }


$collapse-transition-speed: .2s !default;
$collapse-transition-style: ease !default;
$message-body-padding: 1.25em 1.5em !default;

.is-collapsible {
	height: 0;
	overflow-y: hidden;
	transition: height $collapse-transition-speed $collapse-transition-style;

	&.is-active {
		transition: height $collapse-transition-speed $collapse-transition-style; }

	&.message-body {
		padding: 0 !important;
		.message-body-content {
			padding: $message-body-padding; } } }

.card.question {
  background-color: #E1E2E1; }

.card.question .card-content {
  background-color: white; }

.card-content .textarea {
  border: solid 1px lightgrey; }

.candidate-rating {
  display: inline-flex; }

.candidate-rating > span {
  line-height: 1; }

.star-rating .svg-inline--fa {
  margin-bottom: 3px;
  cursor: pointer; }

.question-catalog input {
  border-color: #d0d1cd; }

.navbar, .navbar-menu {
  background-color: $primary-blue; }

.navbar-item, .navbar-link {
  color: white; }

.navbar-item:active {
  background-color: #8bc34a; }

input, textarea, select {
  border-color: grey !important;
  background-color: #F0F0F0 !important; }

.button.is-success {
  background-color: #01579b; }

.button.is-success:active, .button.is-success:hover {
  background-color: #4f83cc; }

.table > thead {
  background-color: #E1E2E1; }

.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  opacity: 0;
  z-index: -1;
  transition: opacity .3s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;

  .loader {
    height: 80px;
    width: 80px; }

  &.is-active {
    opacity: 1;
    z-index: 1; } }

.is-loading {
  position: relative; }

.mde-preview-content ul {
  list-style: disc; }

.basic-content {
  padding: 1.5rem 0; }

.navbar-profile {
  background-color: #01579b; }

.navbar-profile .navbar-link {
  background-color: #01579b !important;
  border: none; }

.navbar-profile .navbar-item {
  color: #fff; }

.navbar-profile .navbar-item {
  @media screen and (min-width: 1024px) {
    color: #363636; } }

.navbar-profile-button,  .navbar-link:hover {
  color: #fff; }

.navbar-item:active {
  background-color: #01579b; }

.avatar-circle {
  width: 36px;
  height: 36px;
  background-color: #fff;
  text-align: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%; }

.avatar-circle.has-background-primary-dark {
  background-color: #01579b; }

.initials {
  position: relative;
  top: 9px;
  font-size: 18px;
  line-height: 18px;
  color: #01579b;
  font-family: "Courier New", monospace;
  font-weight: bold; }

.avatar-circle.has-background-primary-dark .initials {
  color: #fff; }

.navbar-link:not(.is-arrowless)::after {
  border-color: #fff; }

.field .level {
  margin-bottom: 0.15em; }

.add-position {
  min-width: 300px; }

.add-position .message-body {
  border-width: 2px; }

.is-google svg {
  color: #dd4b39; }

.is-twitter svg {
  color: #1da1f2; }

.is-facebook svg {
  color: #4167B2; }

.question-org-icon {
  visibility: visible; }

@media screen and (min-width: 768px) {
  .question-org-icon {
    visibility: hidden; } }

.question .card-header-title {
  padding: 0.4rem; }
